import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const NetworkSecurityTestingPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  itsection,
  wcfsection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns" style={{ height: 80 }}>
            <div className="column is-6"></div>
            <div className="column is-6 mobile-center" >
              <div style={{ maxWidth: 350 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${itsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "8rem" }}>
            <Title title={itsection.title} color={itsection.textcolor} />
            <Title title={itsection.secondtitle} color={itsection.textcolor} />
            <SubTitle title={itsection.subheader} color={itsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={itsection.descriptions} color={itsection.textcolor} margin="1rem 0" />
          </div>
        </div>
      </section>
      <section className="section" style={{ margin: "4rem 0 2rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Title title={wcfsection.title} />
              <Title title={wcfsection.secondtitle} />
              <SubTitle title={wcfsection.subheader} margin="1.5rem 0" />
              <Descriptions descriptions={wcfsection.sidedescriptions} margin="1rem 0 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={wcfsection.image} />
              </div>
            </div>
          </div>
          <Descriptions descriptions={wcfsection.descriptions} margin="1rem 0" />
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

NetworkSecurityTestingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  itsection: PropTypes.object,
  wcfsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const NetworkSecurityTestingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <NetworkSecurityTestingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        itsection={frontmatter.itsection}
        wcfsection={frontmatter.wcfsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

NetworkSecurityTestingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default NetworkSecurityTestingPage

export const pageQuery = graphql`
  query NetworkSecurityTestingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "network-security-testing" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 350, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        itsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        wcfsection {
          title
          secondtitle
          subheader
          sidedescriptions
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 350, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
